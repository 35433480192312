import styled from 'styled-components'
import { Button, Modal } from '@pinchenterprisesnpm/friday-ui'

const { Header } = Modal

// #region Styled components

const ButtonResetStyles = styled.button`
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  vertical-align: top;

  &:focus,
  &:hover {
    outline: none;
  }

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
`

const TimePickerWrapper = styled.div<{ $width?: string }>`
  width: ${({ $width }) => $width ?? '150px'};
  height: 30px;
  position: relative;
`

const TimePickerHeader = styled(Header)`
  text-align: center;
  font-weight: ${(props) => props.theme.font.weights.light};
`

const TimePickerContent = styled.div`
  padding: 0;
  background: ${(props) => props.theme.palette.white};
  flex: 15;
`

const TimePickerIcon = styled(ButtonResetStyles)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: ${(props) => props.theme.palette.primary};
  display: inline-block;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: ${(props) => `${props.theme.mp.xs}`};
  &:focus {
    box-shadow: 0 0 0 1px ${(props) => props.theme.palette.primary};
  }
  &:disabled {
    color: ${(props) => props.theme.palette.muted};
    opacity: 0.4;
    cursor: default;
  }
`

const TimePickerInput = styled(ButtonResetStyles)`
  background: ${(props) => props.theme.palette.white};
  color: ${(props) => props.theme.palette.dark || 'black'};
  height: 100%;
  border: 1px solid ${(props) => props.theme.palette.light};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: inline-block;
  &:focus {
    box-shadow: 0 0 0 1px ${(props) => props.theme.palette.primary};
  }
  &:disabled {
    color: ${(props) => props.theme.palette.muted};
    cursor: default;
  }
`

const TimePickerInputElement = styled.span`
  padding: ${(props) => `${props.theme.mp.xs}`};
  display: inline-block;
`

const TimePickerPopup = styled.div<{ modal?: boolean; styleTop?: any; styleLeft?: any }>`
  position: ${(props) => (props.modal ? 'static' : 'absolute')};
  box-shadow: ${(props) => !props.modal && '0px 1px 9px -2px rgba(0, 0, 0, 0.2)'};
  border: ${(props) => !props.modal && `1px solid ${props.theme.palette.light}`};
  background: ${(props) => props.theme.palette.white};
  display: flex;
  align-items: center;
  justify-content: center;
  top: ${(props) => (props.styleTop ? `${props.styleTop + 30}px` : `55%`)};
  left: ${(props) => (props.styleLeft ? `${props.styleLeft}px` : `55%`)};
  z-index: 30;
  &:focus {
    outline: 1px solid ${(props) => props.theme.palette.primary};
  }
  z-index: 9999999;
  min-width: 114px;
`

const TimePickerValues = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 33.33%;
`

const TimePickerListValue = styled(ButtonResetStyles)<{ modal?: boolean }>`
  padding: ${(props) => `${props.theme.mp.sm}`};
  font-size: ${(props) =>
    !props.modal ? props.theme.font.sizes.default : props.theme.font.sizes.large};
`

const TimePickerArrow = styled(ButtonResetStyles)<{ modal?: boolean }>`
  padding: ${(props) => `${props.theme.mp.sm}`};
  font-size: ${(props) =>
    props.modal ? props.theme.font.sizes.large : props.theme.font.sizes.default};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${(props) => 'rgba(3,169,244,0.06)'};
    cursor: pointer;
  }
  &:disabled {
    background-color: #cccccc;
    color: #666666;
  }
`

/**
 * Custom footer wrapper, in case we need different buttons from the ones in the Modal Component
 */
const TimePickerFooter = styled.div`
  background: ${(props) => props.theme.palette.white};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const TimePickerOK = styled(Button)`
  flex: 1;
  justify-content: center;
`

// #endregion Styled components

export {
  TimePickerWrapper,
  TimePickerIcon,
  TimePickerInput,
  TimePickerInputElement,
  TimePickerPopup,
  TimePickerValues,
  TimePickerListValue,
  TimePickerArrow,
  TimePickerFooter,
  TimePickerOK,
  TimePickerHeader,
  TimePickerContent,
}
