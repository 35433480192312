import React from 'react'
import { TimePickerWrapper, TimePickerIcon, TimePickerInput, TimePickerInputElement } from './style'
import {
  createAndAppendPopupElement,
  normalizeValue,
  destroyAndCloseLast,
  create,
  Portal,
  noop,
  setupListeners,
  HOURS_TYPE,
  MINUTES_TYPE,
} from './utils'
import Popup, { PopupFooter } from './Popup'
import { faChevronDown, faChevronUp, faClock } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from '@pinchenterprisesnpm/friday-ui'
import { isMobile } from 'helpers/helperFunctions'

export type TTimePickerValueObject = {
  hour: HOURS_TYPE
  minute: MINUTES_TYPE
  second: string
  period: string
}

export type TTimePickerValue = TTimePickerValueObject | string

type TTimePickerProps = {
  /**
   * Value of the time picker in 12 hour format. Eg. '12:00 AM'
   */
  value: TTimePickerValue
  /**
   * Hours not available for selection
   */
  disabledHours?: any[]
  /**
   * Minutes not available for selection
   */
  disabledMinutes?: any[]
  /**
   * Show the period toggle in the popup instead of next to the time input
   */
  periodInPopup?: boolean
  /**
   * Whether the time picker's trigger elements are disabled or not
   */
  isDisabled?: boolean
  /**
   * On change callback
   */
  onChange?: (value: string) => void
  /**
   * Text in the header of the popup on mobile
   */
  headerText?: string
  /**
   * Width of TimePicker
   */
  width?: string
  [prop: string]: any
}

type TTimePickerState = {
  isOpen: boolean
}

setupListeners()
/**
 * Time picker
 */
class TimePicker extends React.Component<TTimePickerProps, TTimePickerState> {
  static popupElement = createAndAppendPopupElement()
  static popperInstance: any = null
  static last = noop

  triggerRef = React.createRef<HTMLDivElement>()
  state = {
    isOpen: false,
  }

  toggleOpen = () => {
    this.setState(({ isOpen }) => {
      if (isOpen === false && !isMobile()) {
        const trigger = this.triggerRef.current as HTMLDivElement
        const popup = TimePicker.popupElement

        if (TimePicker.last !== this.closePopup) {
          destroyAndCloseLast()
          create(trigger, popup)
          TimePicker.last = this.closePopup
        }
      }

      return { isOpen: !isOpen }
    })
  }

  closePopup = () => this.setState({ isOpen: false })

  valueToString = (value: TTimePickerValueObject) => {
    const { hour, minute, period } = normalizeValue(value)

    return `${hour}:${minute} ${period}`
  }

  changeValue = (val: string, type: keyof TTimePickerValueObject) => {
    const { onChange, value: providedValue } = this.props
    let value = String(val)

    if (value.length === 1) {
      value = `0${val}`
    }

    const ret = {
      ...normalizeValue(providedValue),
      [type]: value,
    }

    onChange && onChange(this.valueToString(ret))
  }

  changeValues = (values: Partial<TTimePickerValueObject>) => {
    const { onChange, value: providedValue } = this.props

    const ret = {
      ...normalizeValue(providedValue),
      ...values,
    }

    onChange && onChange(this.valueToString(ret))
  }

  togglePeriod = () => {
    const { value: providedValue, onChange } = this.props

    const value = normalizeValue(providedValue)
    const { period } = value
    const newPeriod = period === 'AM' ? 'PM' : 'AM'

    const ret = {
      ...value,
      period: newPeriod,
    }
    onChange && onChange(this.valueToString(ret))
  }

  render() {
    const { value, isDisabled, headerText, width } = this.props
    const normalizedValue = normalizeValue(value)
    const { hour, minute, period } = normalizedValue
    const { isOpen } = this.state

    return (
      <>
        <TimePickerWrapper
          ref={this.triggerRef}
          onClick={isDisabled ? () => {} : this.toggleOpen}
          className='timePickerTrigger'
          $width={width}
        >
          <TimePickerIcon type='button' className='timePickerTrigger' disabled={isDisabled}>
            <FontAwesomeIcon icon={faClock} className='timePickerTrigger' color='white' />
          </TimePickerIcon>
          <TimePickerInput disabled={isDisabled} type='button' className='timePickerTrigger'>
            <TimePickerInputElement className='timePickerTrigger'>{hour}</TimePickerInputElement>:
            <TimePickerInputElement className='timePickerTrigger'>{minute}</TimePickerInputElement>
            <TimePickerInputElement className='timePickerTrigger'>{period}</TimePickerInputElement>
            <TimePickerInputElement className='timePickerTrigger'>
              {isOpen ? (
                <FontAwesomeIcon
                  icon={faChevronUp}
                  className='timePickerTrigger'
                  style={{ fontSize: '10px', verticalAlign: '0.1em' }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className='timePickerTrigger'
                  style={{ fontSize: '10px', verticalAlign: '0.1em' }}
                />
              )}
            </TimePickerInputElement>
          </TimePickerInput>
        </TimePickerWrapper>
        {isOpen && !isMobile() && (
          <Portal to={TimePicker.popupElement}>
            <Popup
              value={normalizedValue}
              changeValue={this.changeValue as (value: string, type: string) => void}
              changeValues={this.changeValues}
              togglePeriod={this.togglePeriod}
            />
          </Portal>
        )}
        {isMobile() && (
          <Modal isOpen={isOpen} closeModal={this.closePopup} width={250} height={250}>
            <Modal.Header title={headerText}></Modal.Header>
            <Modal.Content>
              <Popup
                value={normalizedValue}
                changeValue={this.changeValue as (value: string, type: string) => void}
                changeValues={this.changeValues}
                togglePeriod={this.togglePeriod}
                modal
              />
            </Modal.Content>
            <PopupFooter />
          </Modal>
        )}
      </>
    )
  }
}

export default TimePicker
